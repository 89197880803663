import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import PageTitle from "../../common/PageTitle/PageTitle";
import {sessionStorageUtilityValues} from "../../../helpers/sessionStorageHelper";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetBrokeragePropertyByRef,
    GetPropertiesCountries,
    onChangeIsAddressModalOpened,
    onUpdateBrokeragePropertyField, selectBrokerageUpdatedPropertyDefaultFields,
    selectBrokerageUpdatedPropertyFields,
    selectCountriesArray,
    selectIsAllPropertiesAddressModalOpened,
    UpdateBrokerageProperty
} from "../../../store/propertiesReducer";
import s from './EditProperty.module.scss'
import {Input, Select} from "antd";
import { format } from 'date-fns';
import AllPropertiesAddressForm from "../../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {GetPropertiesTypes, selectPropertiesTypes} from "../../../store/utilityReducer";
import SecondaryButton from "../../common/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import {useNavigate} from "react-router";

const EditProperty = () => {
    return (
        <PageWithSidebar>
            <EditPropertyComponent/>
        </PageWithSidebar>
    )
}

const EditPropertyComponent = () => {
    const countries = useAppSelector(selectCountriesArray)
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty()
    const dispatch = useAppDispatch()
    const editBrokeragePropertyDefaultFormFields = useAppSelector(selectBrokerageUpdatedPropertyDefaultFields)
    const [fieldsWithError, setFieldsWithError] = React.useState(false)
    const formFields = useAppSelector(selectBrokerageUpdatedPropertyFields)
    const isAddressModalOpened = useAppSelector(selectIsAllPropertiesAddressModalOpened)
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate()
    const propertiesTypes = useAppSelector(selectPropertiesTypes)

    useEffect(() => {
        dispatch(GetBrokeragePropertyByRef(currentBrokerageProperty.PROP_ID))
    }, [dispatch, currentBrokerageProperty.PROP_ID])

    useEffect(() => {
        dispatch(GetPropertiesCountries())
        dispatch(GetPropertiesTypes())
    }, [dispatch])

    const countryOptions = countries.map((a: any) => ({
        value: a.cntry_desc_default,
        label: a.cntry_desc_default,
    }))

    const domainStatuses = [
        {
            value: 1, label: 'Inactive'
        },
        {
            value: 2, label: 'Active'
        }
    ]

    const propertiesTypesValues = propertiesTypes.map((type: { id: number, value: string }) => ({
        value: `${type.id}`,
        label: `${type.value}`,
    }))

    const onFormInputChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const {value} = e.target;
        dispatch(onUpdateBrokeragePropertyField({fieldKey: inputName, value: value}))
    }

    const onFormSelectChange = (value: string, inputName: string) => {
        dispatch(onUpdateBrokeragePropertyField({fieldKey: inputName, value: Number(value)}))
    }
    const handleStreetAddressChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const value = e.target.value;
        if (/^[0-9]{0,5}$/.test(value)) {
            onFormInputChange(e, inputName);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const isValid = /^[/-]*$/.test(newValue); // Regex to allow only '/' and '-'

        if (isValid) {
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'address.addr_no_connect', value: e.target.value}))
        }
    };

    const onChangeDomainStatus = (value: number, domain: string) => {
        if(domain === 'status_cd'){
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_cd', value: value}))
        }
        else if(domain === 'status_or'){
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_or', value: value}))
        }
        else if(domain === 'status_rr'){
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_rr', value: value}))
        }
        else{
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_rs', value: value}))
        }
    }

    const onUpdateProperty = () => {
        if (isProcessing) return;
        setIsProcessing(true);
        if ((formFields.address.cntry_desc_local === null || Number(formFields.address.cntry_desc_local) === 0)
            ||
            (formFields.address.addr_city === null || formFields.address.addr_city === '')
            ||
            (formFields.address.addr_zip === null || formFields.address.addr_zip === '')
            ||
            (formFields.address.addr_street === null || formFields.address.addr_street === '')) {
            setFieldsWithError(true)
            setIsProcessing(false)
        }
        else{
            dispatch(UpdateBrokerageProperty({ref: currentBrokerageProperty.PROP_ID, reqData: formFields}))
                .then(() => {
                    setIsProcessing(false)
                    navigate('/properties')
                })
        }

    }

    return (
        <div className={s.inner}>
            <div className={s.inner__content}>
                <PageTitle
                    isBackButton={true}
                    backLinkSecondText={`Back`}
                    backLink={'/properties'}
                />
                <div>
                    <p className={s.propertyAddress}>{currentBrokerageProperty.PROP_ID} {editBrokeragePropertyDefaultFormFields?.address?.addr_quarter}, {editBrokeragePropertyDefaultFormFields?.address?.addr_street}, {editBrokeragePropertyDefaultFormFields?.address?.addr_zip} {editBrokeragePropertyDefaultFormFields?.address?.addr_city}</p>
                    <div className={s.tabButtons}>
                        <button
                            className={`${s.tabButtons__button} ${s.tabButtons__button_active}`}
                            // onClick={() => onChangeGridTab('Reqs')}
                            style={{position: "relative"}}
                        >
                            Address
                        </button>
                    </div>
                    <div className={s.form}>
                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>Short desc.</label>
                                <Input placeholder={'Enter short desc.'}
                                       value={formFields.description!!}
                                       onChange={(e) => onFormInputChange(e, 'description')}/>
                            </div>
                            <div className={s.form__row}>
                                <label>Property type</label>
                                <Select
                                    onChange={(e) => onFormSelectChange(e, 'class_index')}
                                    style={{width: '100%'}}
                                    placeholder={'Select type'}
                                    className={'reqCreateFormSelect'}
                                    value={propertiesTypesValues.find(
                                        (d) => Number(d.value) === Number(formFields.class_index)
                                    )?.label}
                                >
                                {
                                    propertiesTypesValues.map((d: { value: string, label: string }) => {
                                        return (
                                            <option
                                                key={d.value}
                                                value={d.value}
                                            >
                                                {d.label}
                                            </option>
                                        )
                                    })
                                }
                                </Select>
                            </div>
                        </div>
                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>Country<span>*</span></label>
                                <Select
                                    showSearch
                                    placeholder="Select country"
                                    value={countryOptions.find((c) => c.value === formFields.address.cntry_desc_local)?.label || null}
                                    onChange={(value) => onFormSelectChange(value !== undefined ? value : null, 'address.cntry_desc_local')}
                                    style={{ width: '100%' }}
                                    className={'reqCreateFormSelect'}
                                    options={countryOptions}
                                    optionFilterProp="label"
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option?.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                                {(formFields.address.cntry_desc_local === null || Number(formFields.address.cntry_desc_local) === 0) && fieldsWithError &&
                                    <p className={s.form__error}>Please select country!</p>}
                            </div>
                            <div className={s.form__row}>
                                <label>State</label>
                                <Input placeholder={'Enter state'}
                                       value={formFields.address.addr_state_admin_level_1!!}
                                       onChange={(e) => onFormInputChange(e, 'address.addr_state_admin_level_1')}/>
                            </div>
                            <div className={s.form__row}>
                                <label>City<span>*</span></label>
                                <Input placeholder={'Enter city'}
                                       value={formFields.address.addr_city!!}
                                       onChange={(e) => onFormInputChange(e, 'address.addr_city')}/>
                                {(formFields.address.addr_city === null || formFields.address.addr_city === '') && fieldsWithError &&
                                    <p className={s.form__error}>Please enter your city!</p>}
                            </div>
                        </div>
                        <div className={s.form__rowDouble}>
                            <div className={s.form__row}>
                                <label>Post code<span>*</span></label>
                                <Input placeholder={'Enter post code'}
                                       value={formFields.address.addr_zip!!}
                                       onChange={(e) => onFormInputChange(e, 'address.addr_zip')}/>
                                {(formFields.address.addr_zip === null || formFields.address.addr_zip === '') && fieldsWithError &&
                                    <p className={s.form__error}>Please enter your post code!</p>}
                            </div>
                            <div className={s.form__row}>
                                <label>House number</label>
                                <div style={{
                                    display: 'flex',
                                    gap: '4px'
                                }}>
                                    <Input
                                        value={formFields.address.addr_nf_no_from!!}
                                        style={{
                                            width: '22.5%'
                                        }}
                                        onChange={(e) => handleStreetAddressChange(e, 'address.addr_nf_no_from')}/>
                                    <Input
                                        style={{
                                            width: '22.5%'
                                        }}
                                        value={formFields.address.addr_no_from_rest!!}
                                        onChange={(e) => onFormInputChange(e, 'address.addr_no_from_rest')}/>
                                    <Input
                                        style={{
                                            width: '10%'
                                        }}
                                        value={formFields.address.addr_no_connect!!}
                                        placeholder={'/'}
                                        onChange={(e) => handleInputChange(e)}/>
                                    <Input
                                        style={{
                                            width: '22.5%'
                                        }}
                                        value={formFields.address.addr_nf_no_to!!}
                                        onChange={(e) => handleStreetAddressChange(e, 'address.addr_nf_no_to')}/>
                                    <Input
                                        style={{
                                            width: '22.5%'
                                        }}
                                        value={formFields.address.addr_no_to_rest!!}
                                        onChange={(e) => onFormInputChange(e, 'address.addr_no_to_rest')}/>
                                </div>
                            </div>
                        </div>
                        <div className={s.form__row}>
                            <label>Street Address<span>*</span></label>
                            <Input placeholder={'Enter street address'}
                                   value={formFields.address.addr_street!!}
                                   onChange={(e) => onFormInputChange(e, 'address.addr_street')}/>
                            {(formFields.address.addr_street === null || formFields.address.addr_street === '') && fieldsWithError &&
                                <p className={s.form__error}>Please enter your street address!</p>}
                        </div>
                    </div>
                    <p className={s.mapPin} onClick={() => dispatch(onChangeIsAddressModalOpened(true))}>Map Pin</p>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        gap: '32px'
                    }}>
                        {
                            formFields.status_cd !== null
                            &&
                            <div style={{
                                width: '25%'
                            }}>

                                <div className={s.form__row}>
                                    <label>Status (CD)</label>
                                    <Select
                                        onChange={(e) => onChangeDomainStatus(e, 'status_cd')}
                                        style={{width: '100%'}}
                                        placeholder={'Select domain status'}
                                        options={domainStatuses}
                                        className={'reqCreateFormSelect'}
                                        value={formFields.status_cd!!}
                                    />
                                </div>
                            </div>
                        }
                        {
                            formFields.status_or !== null
                            &&
                            <div style={{
                                width: '25%'
                            }}>

                                <div className={s.form__row}>
                                    <label>Status (OR)</label>
                                    <Select
                                        onChange={(e) => onChangeDomainStatus(e, 'status_or')}
                                        style={{width: '100%'}}
                                        placeholder={'Select domain status'}
                                        options={domainStatuses}
                                        className={'reqCreateFormSelect'}
                                        value={formFields.status_or!!}
                                    />
                                </div>
                            </div>
                        }
                        {
                            formFields.status_rr !== null
                            &&
                            <div style={{
                                width: '25%'
                            }}>

                                <div className={s.form__row}>
                                    <label>Status (RR)</label>
                                    <Select
                                        onChange={(e) => onChangeDomainStatus(e, 'status_rr')}
                                        style={{width: '100%'}}
                                        placeholder={'Select domain status'}
                                        options={domainStatuses}
                                        className={'reqCreateFormSelect'}
                                        value={formFields.status_rr!!}
                                    />
                                </div>
                            </div>
                        }
                        {
                            formFields.status_rs !== null
                            &&
                            <div style={{
                                width: '25%'
                            }}>

                                <div className={s.form__row}>
                                    <label>Status (RS)</label>
                                    <Select
                                        onChange={(e) => onChangeDomainStatus(e, 'status_rs')}
                                        style={{width: '100%'}}
                                        placeholder={'Select domain status'}
                                        options={domainStatuses}
                                        className={'reqCreateFormSelect'}
                                        value={formFields.status_rs!!}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
            <div>
                <p className={s.created}>
                    <span>Created at</span> {format(new Date(currentBrokerageProperty.DATE_CREATED), 'dd.MM.yyyy')} {currentBrokerageProperty.CREATED_BY}
                </p>
            </div>
            {
                isAddressModalOpened
                &&
                <AllPropertiesAddressForm isPropertyEditMode={true}/>
            }
            <div className={s.buttons}>
                <div onClick={() => navigate('/properties')} onDoubleClick={() => {
                }} style={{
                    width: '100%'
                }}>
                    <SecondaryButton text={'Cancel'} width={'100%'} height={'44px'} isWhiteBg={true}/>
                </div>
                {
                    isProcessing
                        ?
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            gap: '32px'
                        }}
                        >
                            <PrimaryButton disabled={isProcessing} text={'Save'} isAddButton={false} width={'100%'}
                                           height={'44px'} loading={isProcessing}/>
                        </div>
                        :
                        <div style={{
                            width: '100%'
                        }}
                             onClick={onUpdateProperty}
                             onDoubleClick={() => {
                             }}
                        >
                            <PrimaryButton disabled={isProcessing} text={'Save'}
                                           isAddButton={false} width={'100%'}
                                           height={'44px'}/>
                        </div>
                }

            </div>
        </div>

    );
};

export default EditProperty;