import {GridColumns} from "../types/commonTypes";
import {
    ShownAndHiddenColumnsType
} from "../store/propertiesReducer";
import {GridSortItem} from "@mui/x-data-grid";
import {gridApi} from "../app/api";
import { UserDataType } from "../types/userTypes";
import {SystemGridsTypes} from "../types/commonGridsTypes";
import {AppDispatch} from "../store/store";
import {message} from "antd";

export const saveGridConfigs = (
    shownColumns: ShownAndHiddenColumnsType[],
    hiddenColumns:  ShownAndHiddenColumnsType[],
    gridColumns: GridColumns[],
    localSortModel: any,
    userData: UserDataType,
    threeMonthsAgo: Date,
    today: Date,
    gridType: SystemGridsTypes,
    dispatch: AppDispatch,
    reqId?: number | null
) => {
    let hiddenColumnsForUpdate: GridColumns[] | ShownAndHiddenColumnsType[] = []
    let shownColumnsForUpdate: GridColumns[] | ShownAndHiddenColumnsType[] = []
    if (hiddenColumns.length) {
        // eslint-disable-next-line
        hiddenColumnsForUpdate = hiddenColumns.map((hiddenCol: ShownAndHiddenColumnsType, index: number) => {
            let updatedColumn = gridColumns.find((gridCol: GridColumns) => gridCol.COL_TITLE === hiddenCol.headerName);
            if (updatedColumn) {
                updatedColumn = { ...updatedColumn, COL_WIDTH: 0, COL_POS: shownColumns.length + index };
                return { ...hiddenCol, ...updatedColumn } as GridColumns;
            }
        }).filter((col): col is GridColumns => col !== undefined);
    } else {
        hiddenColumnsForUpdate = [];
    }
    if (shownColumns.length) {
        // eslint-disable-next-line
        shownColumnsForUpdate = shownColumns.map((shownCol: ShownAndHiddenColumnsType) => {
            let updatedColumn = gridColumns.find((gridCol: GridColumns) => gridCol.COL_TITLE === shownCol.headerName);
            if (updatedColumn) {
                updatedColumn = { ...updatedColumn, COL_WIDTH: shownCol.width! };
                return { ...shownCol, ...updatedColumn } as GridColumns;
            }
            return null;
        }).filter((col): col is GridColumns => col !== null);
    } else if (!shownColumns.length) {
        shownColumnsForUpdate = shownColumns
    }
    if (shownColumnsForUpdate) {
        shownColumnsForUpdate = shownColumnsForUpdate
            ?.map((g: any, index: number) => {
                return {
                    COL_NAME: g.field,
                    COL_OPTIONS: g.col_options,
                    COL_POS: index,
                    COL_TITLE: g.headerName,
                    COL_TYPE: g.dataType,
                    COL_VALUE: g.col_value,
                    COL_WIDTH: g.COL_WIDTH!
                } as GridColumns;
            })
            .filter((c: any) => {
                return c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED' || (gridType === 'Requirements/Cold Offer' && c.COL_NAME !== 'ACTIONS');
            });

        hiddenColumnsForUpdate = hiddenColumnsForUpdate
            ?.map((g: any) => {
                return {
                    COL_NAME: g.field,
                    COL_OPTIONS: g.col_options,
                    COL_POS: g.COL_POS,
                    COL_TITLE: g.headerName,
                    COL_TYPE: g.dataType,
                    COL_VALUE: g.col_value,
                    COL_WIDTH: g.COL_WIDTH
                } as GridColumns;
            })
            .filter((c: any) => {
                return c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED' || (gridType === 'Requirements/Cold Offer' && c.COL_NAME !== 'ACTIONS');
            });
    }
    function mergeArrays(hiddenColumns: GridColumns[], shownColumns: GridColumns[]) {
        const result = [];
        for (const hiddenColumn of hiddenColumns) {
            const matchingObjS = shownColumns.find((objS: any) => objS.COL_NAME === hiddenColumn.COL_NAME);
            if (matchingObjS) {
                hiddenColumn.COL_WIDTH = matchingObjS.COL_WIDTH;
            }
            result.push(hiddenColumn);
        }
        for (const shownColumn of shownColumns) {
            if (!result.find(obj => obj.COL_NAME === shownColumn.COL_NAME)) {
                result.push(shownColumn);
            }
        }
        return result;
    }
    const sortConditionStrArray = Object.keys(localSortModel).map(key => {
        // @ts-ignore
        return {"field": localSortModel[key].field, "sort": localSortModel[key].sort};
    });
    const sortConditionStr = sortConditionStrArray.filter((i: GridSortItem) => i.field !== 'ACTIVITY_EXISTED')?.map((item: any) => `${item.field} ${item.sort.toUpperCase()}`).join(', ')

    if (gridType === 'Requirements'){
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsForUpdate as GridColumns[], shownColumnsForUpdate as GridColumns[]),
            p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'REQ_ID DESC',
            grid_type: 'REQ_DATA',
        }
        gridApi.updateGridColumnData(req)
            .then(() => {
                message.success('Grid configs were saved')

            })
            .catch((e) => {
                console.log(e)
            })
    }
    else if (gridType === 'Requirements/Cold Offer'){
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsForUpdate as GridColumns[], shownColumnsForUpdate as GridColumns[]),
            p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'REQ_ID DESC',
            grid_type: 'REQS_COLD_OFFER',
        }
        gridApi.updateGridColumnData(req)
            .then(() => {
                message.success('Grid configs were saved')

            })
            .catch((e) => {
                console.log(e)
            })
    }
    else if(gridType === 'Properties' || gridType === 'Surfaces'){

        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsForUpdate as GridColumns[], shownColumnsForUpdate as GridColumns[]),
            p_row_req: reqId!!,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : gridType === 'Properties' ? 'PROP_ID DESC' : 'SURF_REF DESC',
            grid_type: gridType === 'Properties' ? null : 'WE_SURFPROPDETAILS',
        }
        gridApi.updateGridColumnData(req)
            .then(() => {
                message.success('Grid configs were saved')

            })
            .catch((e) => {
                console.log(e)
            })
    }
    else if(gridType === 'Requirements/Searched by contacts'){
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsForUpdate as GridColumns[], shownColumnsForUpdate as GridColumns[]),
            p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'REQ_ID DESC',
            grid_type: 'REQS_CONTACT',
        }
        gridApi.updateGridColumnData(req)
            .then(() => {
                message.success('Grid configs were saved')

            })
            .catch((e) => {
                console.log(e)
            })
    }

}