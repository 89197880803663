import {Button, Divider, message, Modal, Spin, Upload} from 'antd'
import React, {useEffect, useState} from 'react'
import classes from './UploadTemplatesModal.module.css'
import {ReactComponent as CloseIcon} from './../../../../img/icons/closeIcon.svg'
import {ReactComponent as PngIcon} from './../../../../img/icons/pngIcon.svg'
import {ReactComponent as DeleteIcon} from './../../../../img/icons/delete.svg'
import {GetCompaniesByPeriodThunk, selectCompanies} from '../../../../store/reportReducer'
import {useAppDispatch, useAppSelector} from '../../../../app/hooks'
import {MarketingReportType} from '../../../../types/parametersTypes'
import {dummyRequest, getBase64, normFile} from "../../../../helpers/fileHelpers";
import {saveAs} from 'file-saver'
import {
    GetMarketingTemplateThunk, selectClosedModal,
    selectTemplatesForImageUpload, setTemplatesForImageUpload,
    UpdateMarketingTemplatesThunk
} from "../../../../store/parametersReducer";
import moment from "moment";


const UploadTemplatesModal: React.FC<UploadTemplatesModalPropTypes> = ({openedRecord, closeModal, domains, closeEmptyModal}) => {
    const dispatch = useAppDispatch()
    const companies = useAppSelector(selectCompanies)
    let templatesForImageUpload = useAppSelector(selectTemplatesForImageUpload)
    const closedModal = useAppSelector(selectClosedModal)
    // eslint-disable-next-line
    const [domain, setDomain] = useState(openedRecord && openedRecord[0].domain)
    const [marketingTemplates, setMarketingTemplates] = useState([])
    const [firstRecord, setFirstRecord] = useState<any>({})
    const [secondRecord, setSecondRecord] = useState<any>({})
    const [thirdRecord, setThirdRecord] = useState<any>({})
    const [fourthRecord, setFourthRecord] = useState<any>({})
    useEffect(() => {
        if (!companies.length) {
            dispatch(GetCompaniesByPeriodThunk())
        }
        // eslint-disable-next-line
    }, [dispatch])
    useEffect(() => {
        if (openedRecord) {
            openedRecord.forEach((r: any) => {
                dispatch(GetMarketingTemplateThunk(r.shortCode))
            })
            let first = openedRecord.find((r: any) => r.ordinalNumber === 1)
            let second = openedRecord.find((r: any) => r.ordinalNumber === 2)
            let third = openedRecord.find((r: any) => r.ordinalNumber === 3)
            let fourth = openedRecord.find((r: any) => r.ordinalNumber === 4)
            setFirstRecord(first)
            setSecondRecord(second)
            setThirdRecord(third)
            setFourthRecord(fourth)
        }
    }, [openedRecord, dispatch])

    let currentRecord = marketingTemplates.length ? marketingTemplates.map((d: any) => d.filter((a: any) => a.domain === domain)).flat(1) : domains.map((d: any) => d.filter((a: any) => a.domain === domain)).flat(1)


    templatesForImageUpload = templatesForImageUpload.map((r: MarketingReportType) =>
        r.shortCode === firstRecord?.shortCode
            ? {...r, ordinalNumber: firstRecord?.ordinalNumber}
            : r.shortCode === secondRecord?.shortCode
                ? {...r, ordinalNumber: secondRecord?.ordinalNumber}
                : r.shortCode === thirdRecord?.shortCode
                    ? {...r, ordinalNumber: thirdRecord?.ordinalNumber}
                    : r.shortCode === fourthRecord?.shortCode
                        ? {...r, ordinalNumber: fourthRecord?.ordinalNumber}
                        : r).sort((itemA: any, itemB: any) => itemA.ordinalNumber - itemB.ordinalNumber)

    const onSave = () => {
        let updatedMarketingTemplates = templatesForImageUpload.filter((a: any) => a.isFieldTouched === true).flat(1)
        if(updatedMarketingTemplates.length){
            updatedMarketingTemplates.forEach((m: any) => {
                const fileToUpload = m.fileForSending;
                const formdata = new FormData();
                formdata.append('template', fileToUpload ? fileToUpload : null);
                dispatch(UpdateMarketingTemplatesThunk({updatedMarketingTemplates: formdata, code: m.shortCode}))
            })
            closeModal()
        }
        else{
           closeEmptyModal()
        }

    }

    templatesForImageUpload = templatesForImageUpload && templatesForImageUpload.map((a: any) => a.ordinalNumber === 1 ? {...a, sortNumber: 3} : a.ordinalNumber === 2 ? {...a, sortNumber: 4} : a.ordinalNumber === 3 ? {...a, sortNumber: 1} : a.ordinalNumber === 4 ? {...a, sortNumber: 2} : a)
    templatesForImageUpload =  templatesForImageUpload && templatesForImageUpload.sort((a: any, b: any) => a.sortNumber - b.sortNumber)
    return (<Modal open={closedModal!}
                   className={`${classes.modal} marketingModal`}
                   footer={false}
                   closable={false}
                   destroyOnClose
                   bodyStyle={{padding: '0px', borderRadius: '8px 8px 0px 0px'}}
        >
            <header className={classes.modalHeader}>
                <h2>Upload templates</h2>
                <CloseIcon onClick={closeEmptyModal}/>
            </header>
            <div className={classes.formWrapper}>
                <div className={classes.domainText}>
                    Domain name<span className={classes.redDot}>*</span>
                </div>
                <div>{openedRecord && openedRecord[0].domain}</div>
                <Divider/>

                <div className={classes.marketingTitle}>
                    Marketing report
                </div>
                {openedRecord ? <div style={{
                        minHeight: '310px'
                    }}>
                        {templatesForImageUpload ? templatesForImageUpload.map((r: MarketingReportType, index: number) => {
                            return (
                                <div className={classes.uploadInner} key={index}>
                                    <div className={classes.uploadInner__marketing}>
                                        <p className={classes.uploadInnerMarketing}>Marketing report:</p>
                                        {index === 0 ? 'Page 1 (EN)' : index === 1 ? 'Page 2 (EN)' : index === 2 ? 'Page 1 (CZ)' : index === 3 ? 'Page 2 (CZ)' : 'Template page'}
                                    </div>
                                    <FileUpload shortCode={r.shortCode}
                                                currentRecord={r}
                                                filename={
                                                    r.ordinalNumber === 1 ?
                                                        r.label.replace('2', '1')
                                                        :
                                                        r.ordinalNumber === 2
                                                            ?
                                                            r.label.replace('3', '2')
                                                            :
                                                            r.ordinalNumber === 3 ?
                                                                r.label.replace('2', '1')
                                                                :
                                                                r.ordinalNumber === 4
                                                                    ?
                                                                    r.label.replace('3', '2')
                                                                    :
                                                                    r.label
                                                }
                                                currentRecords={currentRecord}
                                                setMarketingTemplates={setMarketingTemplates}
                                                marketingTemplates={marketingTemplates.length ? marketingTemplates : domains}
                                                templatesForImageUpload={templatesForImageUpload}
                                    />
                                </div>
                            )
                        })
                        :
                            <Spin style={
                                {
                                    margin: '0 auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '290px'
                                }
                            }
                            />
                        }
                    </div>
                    :
                    <Spin style={
                        {
                            margin: '0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '290px'
                        }
                    }
                    />
                }

                <div className={classes.btnWrapper}>
                    <Button onClick={closeEmptyModal}>
                        Cancel
                    </Button>
                    <Button type='primary' onClick={onSave}>
                        Save
                    </Button>
                </div>
            </div>


        </Modal>
    )
}

const FileUpload: React.FC<{
    filename?: string,
    shortCode?: any,
    currentRecord: MarketingReportType,
    currentRecords: MarketingReportType[],
    setMarketingTemplates: any,
    marketingTemplates: MarketingReportType[],
    templatesForImageUpload: MarketingReportType[]
}> = ({
          filename,
          shortCode,
          currentRecord,
          setMarketingTemplates,
          marketingTemplates, templatesForImageUpload
      }) => {
    const dispatch = useAppDispatch()

    function beforeUpload(file: any) {
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) message.error('File must be less than 2 mb')
        return isLt2M ? true : Upload.LIST_IGNORE
    }

    const addPhoto = async (file: any, code: any) => {
        const fileBase64 = await getBase64(file.originFileObj) as string
        const fileBase64Formatted = fileBase64.split('base64,')[1]
        setMarketingTemplates(marketingTemplates.map((m: any) => m.map((r: any) => r.shortCode === code ? {
            ...r,
            template: fileBase64Formatted,
            fileForSending: file.originFileObj,
            isFieldTouched: true,
            filename: file.name
        } : r)))

        dispatch(setTemplatesForImageUpload(templatesForImageUpload.map((m: any) => m.shortCode === code ? {
            ...m,
            template: fileBase64Formatted,
            fileForSending: file.originFileObj,
            isFieldTouched: true,
            filename: file.name
        } : m)))
    }
    const removePhoto = (code: string) => {
        setMarketingTemplates(marketingTemplates.map((m: any) => m.map((r: any) => r.shortCode === code ? {
            ...r,
            template: null,
            isFieldTouched: true
        } : r)))
        dispatch(setTemplatesForImageUpload(templatesForImageUpload.map((m: any) => m.shortCode === code ? {
            ...m,
            template: null,
            isFieldTouched: true
        } : m)))

    }
    const downloadImage = () => {
        saveAs(`data:image/png;base64, ${currentRecord?.template}`, 'image.png') // Put your image url here.
    }
    return (

        currentRecord.template
            ?
            <div style={{
                width: '100%'
            }}>
                <div className={classes.uploadedFileData}>
                    <div
                        onClick={downloadImage}
                        className={classes.downloadInner}
                    >
                        <img src={`data:image/png;base64, ${currentRecord?.template}`} alt='logo'
                             style={{height: '50px', width: '50px', cursor: 'pointer'}}/>
                        <p className={classes.filename}>{`${filename}.png`}</p>
                    </div>
                    <div className={classes.removePhotoBtn}>
                        <DeleteIcon onClick={(e) => {
                            e.stopPropagation();
                            removePhoto(currentRecord.shortCode)
                        }}
                        />
                    </div>
                </div>
                {
                    currentRecord.dateModified
                        ?
                        <div
                            className={classes.dateDownloaded}>Uploaded {moment(currentRecord.dateModified).format('YYYY.MM.DD')}
                        </div>
                        :
                        <div style={{
                            height: '12px',
                            maxHeight: '12px',
                            opacity: 0
                        }}>
                            1
                        </div>
                }
            </div>

            :
            <div style={{
                width: '100%'
            }}>
                <Upload.Dragger
                    name='files'
                    customRequest={dummyRequest}
                    listType={'picture'}
                    onChange={(e) => normFile(e, addPhoto, shortCode)}
                    accept={'image/png'}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                >
                    <div className={classes.uploadDragger}>
                        <PngIcon/>
                        <div className={classes.text}>
                            <div>
                                Click or drag file to this area to upload
                            </div>
                            <div className={classes.details}>
                                File should be in PNG
                            </div>
                        </div>
                    </div>
                </Upload.Dragger>
                <div style={{
                    height: '12px',
                    maxHeight: '12px',
                    opacity: 0
                }}>
                    1
                </div>
            </div>


    )
}

interface UploadTemplatesModalPropTypes {
    openedRecord: MarketingReportType[]
    closeModal: () => void
    domains: MarketingReportType[]
    closeEmptyModal: () => void
}

export default UploadTemplatesModal

